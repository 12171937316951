html,
body,
#root {
	height: 100%;
	margin: 0;
	padding: 0;
}

body {
	font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol";
}

*,
*:before,
*:after {
	box-sizing: border-box;
}
